import React, { useState } from "react";
import { Link as Link2 } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"
import '../App.css'

import AboutImage from "../assets/images/about.webp";
import ModalVideo from "react-modal-video";
import "../../node_modules/react-modal-video/scss/modal-video.scss";
import  CountUp from "react-countup"; 
import c1 from '../assets/images/client/apollo.png'
import c2 from '../assets/images/client/daimler.png'
import c3 from '../assets/images/client/psa.png'
import c4 from '../assets/images/client/saint.png'
import c5 from '../assets/images/client/simpson.png'
import c6 from '../assets/images/client/renault.png'
import { useEffect,useRef } from 'react';
import { motion } from 'framer-motion'
import { fadeIn } from '../variants';
import Slider from "react-slick";
import NavbarCopy from "./NavbarCopy";
import Footer from "./Footer";
 


export default function AboutMain() {
  const countUpRef = useRef(null)
  const [isVisible, setIsVisible] = useState(false)
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update state when the target element is in the viewport
        if (entry.isIntersecting) {
          setIsVisible(true);
          // Disconnect the observer once the target is visible to prevent further callbacks
          observer.disconnect();
        }
      },
      {
        root: null, // Use the viewport as the root
        rootMargin: '0px', // No margin
        threshold: 0.5, // 0.5 means when 50% of the target is visible
      }
    );

    // Start observing the target element
    if (countUpRef.current) {
      observer.observe(countUpRef.current);
    }

    // Cleanup function
    return () => {
      if (countUpRef.current) {
        observer.unobserve(countUpRef.current);
      }
    };
  }, []);

  var settings = {
    dots: false,
    infinite: true,
    slidesToScroll: 1,
    autoplay : true,
    prevArrow: null, // Set prevArrow to null to remove the previous button
    nextArrow: null,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 2560, 
        settings: {
          slidesToShow: 6
        }
      },
      {
        breakpoint: 1440, 
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 768, 
        settings: {
          slidesToShow: 2
        }
      }
    ]
  };

  return (
    <>
      <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="about">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 gap-10 items-center">
            <div className="lg:col-span-5">
              <div 
              className="relative">
                <img src={AboutImage} className="rounded-lg shadow-lg relative" alt="" />
                <div className="absolute bottom-2/4 translate-y-2/4 start-0 end-0 text-center">
                  {/* <Link2 to="#" onClick={() => setOpen(true)} className="lightbox h-20 w-20 rounded-full shadow-lg shadow-slate-100 dark:shadow-slate-800 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-blue-900">
                    <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                  </Link2> */}
                </div>
              </div>
            </div>
            {/* end col */}

            <div className="lg:col-span-7">
              <div 
              className="lg:ms-7">
                <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">About us</h3>

                <p className="text-slate-400 dark:text-slate-300 max-w-2xl mb-4 mx-auto">JK Enterprises a sole Proprietorship Firm established in the year 2008 & placed amongst 
                the growing manufacturers, suppliers and service providers of Engineering Infra structure, Printing & Scaffolding works. 
                These products are valued in the market for their wide application areas and ability to constrain heavy pressures & 
                large forces. With an extended operational life, our range has excellent efficiency and performance. Apart from this, we also render after sale services for all our products.</p>
                
                <p className="text-slate-400 dark:text-slate-300 max-w-2xl  mx-auto">Our collection of immaculate products finds bountiful applications in various construction and 
                restorational industries. For this purpose, we have defined a robust infrastructure where the entire production
                process is carried out in confirmation with standards. Furthermore, we flourished an organized and
                well−ventilated warehouse, where all the accessories are stored in a streamlined manner. This makes
                the retrieval and dispatch of the products easy.</p>
              </div>
            </div>
          </div>
          <motion.div
          variants={fadeIn('up',0.3)}
          initial='hidden'
          whileInView={'show'}
          viewport={{once: false, amount: 0.7}} 
          className="xs:flex xs:justify-around xs:item-center mt-10 grid grid-rows-4 grid-flow-col ">
            <p className="mb-4 md:text-4xl text-xl font-medium text-orange-600">
                                                      
                                                      <CountUp start={0} end={150} delay={0} duration={2.5}>
                                                        {({ countUpRef }) => (
                                                          <div>
                                                            Client : <span ref={countUpRef} />+
                                                          </div>
                                                        )}
                                                      </CountUp>
                                                    </p>
            
            <p className="mb-4 md:text-4xl text-xl font-medium text-orange-600">
                                                        <CountUp start={0} end={300} delay={0} duration={2.5}>
                                                        {({ countUpRef }) => (
                                                          <div>Project Done :
                                                            <span ref={countUpRef} />+
                                                          </div>
                                                        )}
                                                      </CountUp></p>
            <p className="md:text-4xl text-xl font-medium text-orange-600">
                                                        <CountUp start={0} end={50} delay={0} duration={5}>
                                                        {({ countUpRef }) => (
                                                          <div>Employers :
                                                            <span ref={countUpRef} />+
                                                          </div>
                                                        )}
                                                      </CountUp></p>
          </motion.div>
        </div>
      </section>
    </>
  );

}

