import React, { useState,useEffect } from 'react'
import { db } from '../Config/Firebase'
import {getDocs,collection,addDoc,deleteDoc,doc} from "firebase/firestore";
import Admin from './Admin';
import { useParams } from 'react-router-dom';
import './Admin.css'

export const AdminProject = () => {

    const [list, setList] = useState([]);
    const { category } = useParams();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);
    

    const workCollectionRef = collection(db, "workDetails");

    const getWork = async () => {
        try {
          const data = await getDocs(workCollectionRef);
          const filteredData = data.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          setList(filteredData);
          console.log("filteredData",filteredData);
        } catch (err) {
          console.error(err);
        }
      };
    
      useEffect(()=>{
        getWork()
      },[])

      const onDeleteWork = async (id) => {
        try {
          await deleteDoc(doc(workCollectionRef, id));
          getWork();
          setShowConfirmation(false);
        } catch (err) {
          console.error(err);
        }
      };
      const handleConfirmDelete = (index) => {
        // Perform delete action here
        console.log('Delete action confirmed');
        setDeleteIndex(index);
        setShowConfirmation(true);

      };
    
      const handleCancelDelete = () => {
        // Cancel delete action
        console.log('Delete action canceled');
        setShowConfirmation(false);
      };



      const filtered1 = list.filter(item => item.Category === category);
    //   const filtered2 = list.filter(item => item.Category === 'Scaffolding');
    //   const filtered3 = list.filter(item => item.Category === 'Conveyor');
    //   const filtered4 = list.filter(item => item.Category === 'Fabrication');


  return (
    <div>
      <div className="admin-page1">
      <div className='nav'><Admin />{category} Project<p></p></div>
        <div>
        <div className='image-container'>
          {filtered1.map((work) => (
            <div key={work.id} className='img-content'>
              <h6>Project Name : {work.ProjectName}</h6>
              <img src={work.ProjectImage} />
              <button onClick={()=>handleConfirmDelete(work)} className='delete-btn'>Delete</button>
            </div>
            ))}
            {showConfirmation  && (
                <div className="confirmation-modal">
                  <p>Are you sure you want to delete?</p>
                  <button onClick={() =>onDeleteWork(deleteIndex.id)} >Yes</button>
                  <button onClick={handleCancelDelete}>No</button>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  )
}
