import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import LogoLight from "../assets/images/jkep.png";
import LogoDark from "../assets/images/jkep.png";

import ModalVideo from "react-modal-video";
import "../../node_modules/react-modal-video/scss/modal-video.scss";
import '../App.css'

import BackgroudImage from "../assets/images/bg/background.jpg";

import Services from "../component/Services";
import About from "../component/About";
import Pricing from "../component/Pricing";
import Blog from "../component/Blog";
import Contact from "../component/Contact";
import Footer from "../component/Footer";
import Portfolio from "../component/Projects";
import Review from "../component/Testimonial";
import Switcher from "../component/Switcher";
import { motion } from 'framer-motion'
import { fadeIn } from '../variants';
import AboutMain from "../component/AboutMain";
import ServiceMain from "../component/ServiceMain";
import ProjectMain from "../component/ProjectMain";
import ContactMain from "../component/ContactMain";

/**
 * Index Two 
 */
export default function IndexTwo() {

    const [isOpen, setMenu] = useState(true);
    const [videoModal, setModal] = useState(false);

    window.addEventListener("scroll", windowScroll);

    const toggleMenu = () => {
        setMenu(!isOpen)
    }

    /**
     * Window scroll
     */
    function windowScroll() {
        const navbar = document.getElementById("navbar");
        if (
            document.body.scrollTop >= 50 ||
            document.documentElement.scrollTop >= 50
        ) {
            navbar.classList.add("is-sticky");
        } else {
            navbar.classList.remove("is-sticky");
        }
    }
    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'instant'});
   }, []);
    return (
        <>
            <div >
                <nav className="navbar bg-white"  id="navbar">
                    <div className="container flex flex-wrap  items-center justify-end" >
                        <a className="navbar-brand" href="/">
                            <span className="inline-block dark:hidden">
                                <img src={LogoDark} className="l-dark  h-16" alt="" />
                                <img src={LogoLight} className="l-light  h-16" alt="" />
                            </span>
                            <img
                                src={LogoLight}
                                className="hidden dark:inline-block"
                                alt=""
                            />
                        </a>

                        <div className="nav-icons flex items-center lg_992:order-2 ms-auto">
                            <button
                                data-collapse="menu-collapse"
                                type="button"
                                className="collapse-btn inline-flex items-center ms-3 text-dark dark:text-white lg_992:hidden"
                                onClick={toggleMenu}
                            >
                                <span className="sr-only">Navigation Menu</span>
                                <i className="mdi mdi-menu mdi-24px"></i>
                            </button>
                        </div>

                        <div
                            className={`${isOpen === true ? 'hidden' : 'block'} navigation lg_992:order-1 lg_992:flex `}
                            id="menu-collapse"
                        >
                            <ul className="navbar-nav nav-dark" id="navbar-navlist">
                                <Link
                                    className="nav-item"
                                    to="/"
                                    
                                    // activeclassname="active"
                                    // spy={true}
                                    // smooth={true}
                                    // duration={500}
                                >
                                    <span className="nav-link">Home</span>
                                </Link>
                                <Link
                                    className="nav-item"

                                    // activeclassname="active"
                                    // spy={true}
                                    // smooth={true}
                                    // duration={500}
                                    to="/about"
                                >
                                    <span className="nav-link">About us</span>
                                </Link>
                                <Link
                                    className="nav-item"
                                    to="/service"
                                    // activeclassname="active"
                                    // spy={true}
                                    // smooth={true}
                                    // duration={500}
                                >
                                    <span className="nav-link">Services</span>
                                </Link>
                                <Link
                                    className="nav-item"
                                    to="/project"
                                    // activeclassname="active"
                                    // spy={true}
                                    // smooth={true}
                                    // duration={500}
                                >
                                    <span className="nav-link">Project</span>
                                </Link>
                               
                                <Link
                                    className="nav-item"
                                    to="/contact"
                                    // activeclassname="active"
                                    // spy={true}
                                    // smooth={true}
                                    // duration={500}
                                >
                                    <span className="nav-link">Contact us</span>
                                </Link>
                            </ul>
                        </div>
                    </div>
                </nav>
                
                <section
                    style={{ backgroundImage: `url(${BackgroudImage})` }}
                    className="py-36 md:py-72 w-full table relative bg-center bg-cover jarallax" data-jarallax data-speed="0.5" id="home">
                    <div className="absolute inset-0 bg-black opacity-70"></div>
                    <div className="container relative">
                        <div className="grid grid-cols-1 text-center">
                            <h4 className="text-white lg:text-5xl text-4xl lg:leading-normal leading-normal font-medium mb-7 position-relative">Step into a world of engineer excellence.</h4>

                            <p className="text-white opacity-50 mb-0 max-w-2xl text-lg mx-auto">Transforming visions into realities with precision and innovation. Your trusted partner for turnkey solutions, fabrication, scaffolding, and civil engineering excellence.</p>

                            {/* <div className="text-center subcribe-form mt-4 pt-2">
                                <form className="relative mx-auto">
                                    <input type="email" id="subemail" name="email" className="rounded-full bg-white opacity-70 border" placeholder="E-mail :" />
                                    <button type="submit" className="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-full">Submit <i className="uil uil-arrow-right"></i></button>
                                </form>
                            </div> */}
                        </div>
                    </div>
                </section>
                {/* <ModalVideo
                    channel="youtube"
                    isOpen={videoModal}
                    videoId="yba7hPeTSjk"
                    onClose={() => this.setModal(false)}
                />
                <div className="relative">
                    <div className="shape overflow-hidden text-gray-50 dark:text-slate-800">
                        <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
                        </svg>
                    </div>
                </div> */}

                {/* About section */}
                <AboutMain />

                {/* Service section */}
                <ServiceMain />

                {/* Portfolio section */}
                <ProjectMain />

                {/* Review section */}
                {/* <Review /> */}

                {/* Pricing section */}
                {/* <Pricing /> */}

                {/* Blog section */}
                {/* <Blog /> */}

                {/* Contact section */}
                <ContactMain/>

                {/* Footer section */}
                <Footer />
                {/* Switcher section */}
                {/* <Switcher /> */}
                </div>
            

        </>
    );

}

