import React, { useEffect, useState } from 'react';
import './Admin.css'
import { Navigate , Outlet, useLocation, useNavigate } from 'react-router-dom'; 
import Cookies from 'js-cookie';



const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate ();
  const [data, setData]= useState(null);
  const location = useLocation();

  const handleSubmit = (event) => {

    event.preventDefault();

    const correctEmail = 'jk@gmail.com';
    const correctPassword = '12345';

    if (email === correctEmail && password === correctPassword) {
      
      setError('');
      setData(true);
      Cookies.set('jk', 'jk@gmail.com');
      navigate('/admin');
      window.location.reload();
      
      console.log('Sign in successful!');
    } else {
      setError('Invalid email or password');
    }
  };
  
  
  

  return (
    <div className='page-container'>
    <div className="sign-in-container">
      <h2>JK Enterprises</h2>
      <h2>Welcome !</h2>

      {error && <p className="error-message">{error}</p>}

      <form className="sign-in-form" onSubmit={handleSubmit}>
        <div>
          <label>Email</label><br/>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <label>Password</label><br/>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit">Sign In</button>
      </form>
      {data ? <Outlet/> : <Navigate to='/login' replace state={{ from : location }} />
      }
    </div>
    </div>
  );
};

export default SignIn;


