// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";



const firebaseConfig = {
  apiKey: "AIzaSyDP3EHqK25RS4UDQnNRxG74CC-ziuGPEGU",
  authDomain: "jk-enterprises-aab95.firebaseapp.com",
  projectId: "jk-enterprises-aab95",
  storageBucket: "jk-enterprises-aab95.appspot.com",
  messagingSenderId: "913599430973",
  appId: "1:913599430973:web:cbd8b99dba59d0a6a54554",
  measurementId: "G-8E0CPDZ7NK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app)


export {app};
