import React, { useEffect, useState } from 'react';
import Admin from './Admin';
import { db } from '../Config/Firebase';
import { getDocs, collection, doc, updateDoc } from 'firebase/firestore';
import { Tabs, TabPanel, Tab, TabList } from 'react-tabs';

export const DashBoard = () => {
  const [list, setList] = useState([]);
  const [status, setStatus] = useState('');

  const enquiryCollectionRef = collection(db, 'enquiryDetails');

  const getEnquiry = async () => {
    try {
      const data = await getDocs(enquiryCollectionRef);
      const filteredData = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setList(filteredData);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getEnquiry();
  }, []);

  const updateStatus = async (id) => {
    try {
      const enquiryDoc = doc(db, 'enquiryDetails', id);
      await updateDoc(enquiryDoc, { Status :"completed"}); // Use the updated status here
      // After updating, refresh the enquiry list
      await getEnquiry();
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const pending = list.filter((item) => item.Status === 'pending');

  return (
    <div>
      <div className="admin-page1">
        <div className="nav">
          <Admin />
          Dash Board
          <p></p>
        </div>
        <div>
          <Tabs>
            <TabList className="TabList">
              <Tab className="Tab">New</Tab>
              <Tab className="Tab">All</Tab>
            </TabList>
            <TabPanel>
              <div className="image-container">
                {pending.map((work) => (
                  <div key={work.id} className="img-content1">
                    <h6>Name : {work.PersonName}</h6>
                    <h6>Mail ID : {work.CompanyMail}</h6>
                    <h6>Phone Number : {work.CompanyPhone}</h6>
                    <h6>Enquiry : {work.Message}</h6>
                    <h6>time : {work.SubmittingTime}</h6>
                    <button className="btn-done" onClick={() => updateStatus(work.id)}>
                      Done
                    </button>
                  </div>
                ))}
              </div>
            </TabPanel>
            <TabPanel>
              <div className="image-container">
                {list.map((work) => (
                  <div key={work.id} className="img-content1">
                    <h6>Name : {work.PersonName}</h6>
                    <h6>Mail ID : {work.CompanyMail}</h6>
                    <h6>Phone Number : {work.CompanyPhone}</h6>
                    <h6>Enquiry : {work.Message}</h6>
                    <h6>time : {work.SubmittingTime}</h6>
                  </div>
                ))}
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </div>
  );
};
