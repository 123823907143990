import React, { useState,useEffect } from 'react'
import { db } from '../Config/Firebase'
import {getDocs,collection,addDoc,deleteDoc,doc} from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import './Admin.css'
import { UploadFile } from '../component/Upload.js'
import Admin from './Admin';





export const AdminForm = () => {
  
  const [projectName, setProjectName] = useState("");
  const [list, setList] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [projectImage, setProjectImage] = useState("");
  const [selectedCategory, setSelectedCategory] = useState('');
  const [headLine, setHeadLine] = useState("");
  const [description, setDescription] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const workCollectionRef = collection(db, "workDetails");

  const [subheading,setSubheading] = useState('')
  const [subparagraph,setSubparagraph] = useState('')
  const [subData, setSubData] = useState([]);
  const [imageUrl, setImageUrls] = useState([]);


  const AddSubData = ()=>{
    if(subheading && subparagraph){
        let newdata = {
                  heading:subheading,
                  paragraph:subparagraph
        } ;
        setSubData([...subData, newdata]);
        setSubheading('');
        setSubparagraph('')
    }
}

  const getWork = async () => {
    try {
      const data = await getDocs(workCollectionRef);
      const filteredData = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setList(filteredData);
      console.log("filteredData",filteredData);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(()=>{
    getWork()
  },[])


  const onSubmitWork = async () => {
    try {
      await addDoc(workCollectionRef, {
        ProjectName : projectName,
        ProjectImage : imageUrl,
        Category : selectedCategory,
        HeadLine : headLine,
        Description : description,
        SubData : subData


      }); 
      getWork();
      setImageUrls([]);
      setProjectName("");
      setSelectedCategory('');
      setHeadLine("");
      setDescription("");
      setCompanyName("");

      setIsSubmitted(true);

    setTimeout(() => {
      setIsSubmitted(false);
    }, 2000); 

    } catch (err) {
      console.error(err);
    }
  };

  console.log(subData)


 
const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
        UploadFile(file)
        .then((downloadURL) => {
          console.log("Download URL:", downloadURL);
          setImageUrls((prevUrls) => [...prevUrls, downloadURL]);
         })
        .catch((error) => {
          console.error("Error:", error);
           });  
    })
  }




  // Function to handle changes in the category dropdown
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };
  const categories = ['Scaffolding','Turnkey', 'Conveyor', 'Fabrication'];

  return (

    <div>
      <div className="admin-page1">
      <div className='nav'><Admin />Add New Projects<p></p></div>
        <div>
        <div className='form-panel' >

            <div className='forms' >
              <label>Project Name</label>
              <input
              className='inputs'
              placeholder="ProjectName"
              onChange={(e) => setProjectName(e.target.value)}
              />
              <label>Choose Project Category</label>
         
              <select className='inputs' value={selectedCategory} onChange={handleCategoryChange}>
              <option value="">Select a category</option>
              {categories.map((category, index) => (
              <option key={index} value={category}>
              {category}
              </option>
              ))}
              </select>
              <input
              className='inputs1'
              type="file"
              placeholder="image"
              onChange={handleImageUpload}
              multiple
              />
            </div>

            <div className='forms'  >
              <label>Headline</label>
              <input
              className='inputs'
              placeholder="Headline"
              onChange={(e) => setHeadLine(e.target.value)}
              />
              
              <label>Description</label>
              <textarea
              className='inputs'
              placeholder="Description"
              onChange={(e) => setDescription(e.target.value)}
              />
            </div>

            <div className='forms' >
            <label>Sub-Heading</label>
              <input
              className='inputs' 
              value={subheading} 
              onChange={(e)=>setSubheading(e.target.value)} 
              placeholder="Sub-Headline"
              />
            <label>Sub-Description</label>
            <textarea 
            className='inputs'
            value={subparagraph} 
            onChange={(e)=>setSubparagraph(e.target.value)}
            placeholder="Sub-Description"
            />
            <button className='add-btn' onClick={()=>{AddSubData()}}>Add</button>
            </div>
            
            <div className='form1'>
            <button className='submit-btn' onClick={onSubmitWork} disabled={!projectName}>submit</button>
            </div>  
            {isSubmitted && <p className="popup">Done!</p>}   
        </div>

        </div>
      </div>

 

















































      {/* <div className='admin-panel'>
         <div className="input-fields" style={{backgroundColor:'white'}}>
          <div>
            <div className='input-style'>
            <label>Project Name</label>
            <input
              placeholder="ProjectName"
              onChange={(e) => setProjectName(e.target.value)}
            />
            <label>Company Name</label>
            <input
              placeholder="CompanyName"
              onChange={(e) => setCompanyName(e.target.value)}
            />
            <label>Choose Project Category</label>
         
            <select value={selectedCategory} onChange={handleCategoryChange}>
              <option value="">Select a category</option>
              {categories.map((category, index) => (
                <option key={index} value={category}>
                  {category}
                </option>
              ))}
            </select>

            </div>

            <input
            className='upload'
            type="file"
            placeholder="image"
            onChange={handleImageUpload}
            multiple
            />
          </div>



          <div className='head-style'>
            <div className='sub-heads'>

          <label>Headline</label>
            <input
            className='head-input'
              placeholder="Headline"
              onChange={(e) => setHeadLine(e.target.value)}
            />
            <label>Description</label>
            <textarea
            className='text-area'
              placeholder="Description"
              onChange={(e) => setDescription(e.target.value)}
              
            />
            </div>


            <div>
            <label>Sub-Heading</label>
            <input  className='head-input1'  type="text" value={subheading} onChange={(e)=>setSubheading(e.target.value)} placeholder="Headline"/>
            <label>Sub-Description</label>
            <textarea value={subparagraph} onChange={(e)=>setSubparagraph(e.target.value)}rows={10} cols={50}  className='text-area'></textarea>
                      
            <button onClick={()=>{AddSubData()}}>Add</button>
                     
          </div>
          <button onClick={onSubmitWork}>Submit</button>
          </div>
          </div>
          </div> */}
    </div>
  )
}



