import React, { useState } from "react";

import { Link } from "react-router-dom";

import LogoLight from "../assets/images/jkep.png";
import LogoDark from "../assets/images/jkep.png";

import ModalVideo from "react-modal-video";
import "../../node_modules/react-modal-video/scss/modal-video.scss";
import '../App.css'

import BackgroudImage from "../assets/images/bg/background.jpg";

import Services from "../component/Services";
import About from "../component/About";
import Pricing from "../component/Pricing";
import Blog from "../component/Blog";
import Contact from "../component/Contact";
import Footer from "../component/Footer";
import Portfolio from "../component/Projects";
import Review from "../component/Testimonial";
import Switcher from "../component/Switcher";
import { motion } from 'framer-motion'
import { fadeIn } from '../variants';

/**
 * Index Two 
 */
export default function NavbarCopy() {

    const [isOpen, setMenu] = useState(true);
    const [videoModal, setModal] = useState(false);

    window.addEventListener("scroll", windowScroll);

    const toggleMenu = () => {
        setMenu(!isOpen)
    }

    /**
     * Window scroll
     */
    function windowScroll() {
        const navbar = document.getElementById("navbar");
        if (
            document.body.scrollTop >= 50 ||
            document.documentElement.scrollTop >= 50
        ) {
            navbar.classList.add("is-sticky");
        } else {
            navbar.classList.remove("is-sticky");
        }
    }
    return (
        <>
            <div >
                <nav className="navbar bg-white"  id="navbar">
                    <div className="container flex flex-wrap  items-center justify-end" >
                        <a className="navbar-brand" href="/">
                            <span className="inline-block dark:hidden">
                                <img src={LogoDark} className="l-dark  h-16" alt="" />
                                <img src={LogoLight} className="l-light  h-16" alt="" />
                            </span>
                            <img
                                src={LogoLight}
                                className="hidden dark:inline-block"
                                alt=""
                            />
                        </a>

                        <div className="nav-icons flex items-center lg_992:order-2 ms-auto">
                            <button
                                data-collapse="menu-collapse"
                                type="button"
                                className="collapse-btn inline-flex items-center ms-3 text-dark dark:text-white lg_992:hidden"
                                onClick={toggleMenu}
                            >
                                <span className="sr-only">Navigation Menu</span>
                                <i className="mdi mdi-menu mdi-24px"></i>
                            </button>
                        </div>

                        <div
                            className={`${isOpen === true ? 'hidden' : 'block'} navigation lg_992:order-1 lg_992:flex `}
                            id="menu-collapse"
                        >
                            <ul className="navbar-nav nav-dark" id="navbar-navlist">
                                <Link
                                    className="nav-item"
                                    to="/"
                                    // activeclassname="active"
                                    // spy={true}
                                    // smooth={true}
                                    // duration={500}
                                >
                                    <span className="nav-link">Home</span>
                                </Link>
                                <Link
                                    className="nav-item"
                                    // activeclassname="active"
                                    // spy={true}
                                    // smooth={true}
                                    // duration={500}
                                    to="/about"
                                >
                                    <span className="nav-link">About us</span>
                                </Link>
                                <Link
                                    className="nav-item"
                                    to="/service"
                                    // activeclassname="active"
                                    // spy={true}
                                    // smooth={true}
                                    // duration={500}
                                >
                                    <span className="nav-link">Services</span>
                                </Link>
                                <Link
                                    className="nav-item"
                                    to="/project"
                                    // activeclassname="active"
                                    // spy={true}
                                    // smooth={true}
                                    // duration={500}
                                >
                                    <span className="nav-link">Project</span>
                                </Link>
                               
                                <Link
                                    className="nav-item"
                                    to="/contact"
                                    // activeclassname="active"
                                    // spy={true}
                                    // smooth={true}
                                    // duration={500}
                                >
                                    <span className="nav-link">Contact us</span>
                                </Link>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>   
        </>
    );

}

