import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from '../Config/Firebase'
import {getDocs,collection} from "firebase/firestore";

import './Details.css' 
import { Swiper , SwiperSlide} from "swiper/react";
import { Navigation, Autoplay  } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation"
import NavbarCopy from "../component/NavbarCopy";
import Footer from "../component/Footer";
import SubDescription from "../SubDescription";

export default function Details (){

    const {objId} = useParams(); 
    const [data, setData] = useState("");
    const [list, setList] = useState([]);
  
    const workCollectionRef = collection(db, "workDetails");
  
    const getWork = async () => {
      try {
        const data = await getDocs(workCollectionRef);
        const filteredData = data.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setList(filteredData);
        const itm = filteredData.find((userItem) => userItem.id == objId);
        setData(itm);
        console.log("item", itm)
        console.log("filteredData",filteredData);
      } catch (err) {
        console.error(err);
      }
    };

    console.log(objId)
    console.log(data)
  
    useEffect(() => {
        getWork(objId);
      }, [objId]);

      console.log("data==", data.ProjectImage)

    return (
       <div>
        <NavbarCopy/>
        <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800 active mt-[50px]" id="portfolio">
                <div className="container">
          {data && (
            <div>
              <h1 className="header">{data.ProjectName}</h1>
              <div className="main-details">
                
                <Swiper modules={[Autoplay]} autoplay={true} slidesPerView={1} className="swiper">
                  {data.ProjectImage.length > 0 && data.ProjectImage.map(item =>
                    <SwiperSlide>
                    <img src={item}className="main-image" />
                    </SwiperSlide>
                    )}
                </Swiper>
                <div className="heading-main">
                <h1 className="main-head">{data.HeadLine}</h1>
                <p className="main-des">{data.Description}</p>
                </div>
              </div>
              {data?.SubData?.length > 0 && data.SubData.map(item=>
                <div>
                <h1 className="main-sub-head">
                    {item.heading}
                </h1>
                <SubDescription  item={item.paragraph} />

              </div>
                )}
            </div>
          )}
          </div>
          </section>

          <Footer/>
        </div>

    );
  }

