import React, { useState,useEffect } from "react";
import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css';
import { Link } from 'react-router-dom';
import { db } from '../Config/Firebase'
import {getDocs,collection,doc} from "firebase/firestore";
import { BsArrowRight } from "react-icons/bs";


import CTABackground from "../assets/images/bg/engineer.jpg";


/**
 * Portfolio section
 */
export default function ProjectMain() {

    const [list, setList] = useState([]);
    const [viewData, setViewData] = useState('');

    const [photoIndex, setActiveIndex] = useState(0);
    const [isOpen, setOpen] = useState(false);

    const handleCLick = (index) => {
        setActiveIndex(index)
        setOpen(true);
    }

    const onclick = async (id) => {
            setViewData(id)
            setOpen(true);
      };

    const workCollectionRef = collection(db, "workDetails");

    const getWork = async () => {
      try {
        const data = await getDocs(workCollectionRef);
        const filteredData = data.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setList(filteredData);
        console.log("filteredData", filteredData);
      } catch (err) {
        console.error(err);
      }
    };
  
    useEffect(()=>{
      getWork()
    },[])
    const filtered1 = list.filter(item => item.Category === 'Scaffolding');
    const filtered2 = list.filter(item => item.Category !== 'Scaffolding' && (item.Category === 'Turnkey' || item.Category === 'Conveyor' || item.Category === 'Fabrication'));
    
    // Combining the filtered arrays
    const combinedFiltered = filtered1.concat(filtered2);
    return (
        <>
            {/* Project Start  */}
            <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800 active" id="portfolio">
                <div className="container">
                <div className="grid grid-cols-1 pb-8 text-center">
                       
                       <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">Our Works &amp; Projects</h3>

                       <p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">Seamless Integration: Turnkey Solutions with Scaffolding, Conveyor, and Fabrication Expertise.</p>
                   </div>
                <div className="portfolio_list">
                  <div className="project-section">
                      {combinedFiltered.map((work) => (
                      <div className="data-div" key={work.id}>
                          <img src={work.ProjectImage} className="image-pro"/>
                          <div className="para-btn">
                          <h1 className="para-pro">{work.ProjectName}</h1>
                          <Link to= {`/details/${work.id} `}><button className="btn-pro">Read More<BsArrowRight className="arrow" /></button></Link>
                          </div>
                      </div>
                      ))}
                  </div>
                  
                </div>
                </div>
            </section>
            {/* Project End  */}
            <section
                style={{ backgroundImage: `url(${CTABackground})` }}
                className="py-24 w-full table relative bg-center bg-cover">
                <div className="absolute inset-0 bg-black opacity-80"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-6 md:text-3xl text-2xl text-white font-medium">QUALITY ASSURANCE</h3>

                        <p className="text-white opacity-50 max-w-xl mx-auto">Quality is the hallmark of our company. Maintaining a zero−defect quality of our products, we have been
                        serving our clients with products . We facilitate various quality testing equipment, that detects even
                        minor glitches in the product. Our associations with established vendors of the industry has allowed
                        team of procurement executives to obtain qualitative raw material. Our team of quality controllers
                        keeps a strict eye on the fabrication of the entire range. This ensures their reliability, dependability and
                        durability.</p>

                        
                    </div>
                </div>
            </section>
        </>
    );

}
