import React from 'react';

const Map = () => {
 

  return (
    <>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15555.663284222732!2d80.15750400253035!3d12.913131718178139!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525ebcb4311aa3%3A0x664a277e29460dc2!2sSanthosapuram%2C%20Chennai%2C%20Tamil%20Nadu!5e0!3m2!1sen!2sin!4v1708499840713!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>    </>
  );
};

export default Map;