import React, { useState, useEffect } from "react";
import './assets/css/tailwind.css';
import './assets/scss/tailwind.scss';

import './assets/scss/icons.scss';

import {
  Navigate,
  Route,
  Routes
} from "react-router-dom";


import Index from "./pages/index.js";
import IndexTwo from "./pages/index-two.js";
import IndexThree from "./pages/index-three.js";
import  About  from './component/About.js';
import  Services  from './component/Services.js'
import  Portfolio  from './component/Projects.js'
import  Contact  from './component/Contact.js'
import Loader from "./component/Loader";
import  Admin  from "./pages/Admin.js";
import { AdminForm  } from "./pages/AdminForm.js";
import  { AdminProject } from "./pages/AdminProject.js";
import Details from "./pages/Details.js";
import SignIn from "./pages/SignIn.js";
import Cookies from "js-cookie";
import { DashBoard } from "./pages/DashBoard.js";

export default function App() {

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    document.documentElement.setAttribute('dir', 'ltr');
    handleRouteChange();
  }, []);


  // Route change method
  const handleRouteChange = () => {
    setLoading(false);
  }
  const cookie = Cookies.get("jk");

  return (
    <>
      {loading && <Loader />}
      <Routes>
        <Route path="/" element={<IndexTwo />} />
        <Route path="/about" element={<About />}/>
        <Route path="/service" element={<Services />} />
        <Route path="/project" element={<Portfolio />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/details/:objId" element={<Details />} />
        <Route path="/admin/DashBoard" element={<DashBoard />} />
        <Route path="/admin/create-project" element={<AdminForm/>}/>
        <Route path="/admin/Project/:category" element={<AdminProject />} />
        {cookie ? 
         <Route path="/admin" element={<AdminForm/>}/> 
                :
          <Route path="/login" element={<SignIn/>}/>
                }
        {cookie?<Route path="/admin" element={<AdminForm/>} /> : <Route path="/login" element={<SignIn />} /> }
         
         {cookie?<Route path="/login" element={<Navigate to="/admin"/>} /> : <Route path="/admin" element={<Navigate to="/login"/>} /> }
        </Routes>
        
    </>
  );

}
