import React, { useState, useEffect } from 'react';
 
function SubDescription({ item }) {
  const [points, setPoints] = useState([]);
 
  useEffect(() => {
    const fetchPoints = async () => {
      const pointsArray = await descriptPoint(item);
      setPoints(pointsArray);
    };
 
    fetchPoints();
  }, [item]); // Run the effect whenever item.paragraph changes
 
  const descriptPoint = async (paragraph) => {
    const points = paragraph.split('\n');
    return points;
  };
 
  return (
      <ul style={{display:"flex", flexDirection:"column", marginTop:"10px", lineHeight:"30px"}}>
      {points.map((point, index) => (
      <li key={index} style={{width:"100%"}}>{point}</li>
      ))}
      </ul>
  );
}
 
export default SubDescription;