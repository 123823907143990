import React, { useEffect, useState } from "react";
import { db } from '../Config/Firebase'
import {getDocs,collection,addDoc,deleteDoc,doc} from "firebase/firestore";


export default function ContactMain() {

    const [name, setName] = useState("");
    const [mail, setMail] = useState("");
    const [companyPhone, setCompanyPhone] = useState("");
    const [message, setMessage] = useState("");
    const [list, setList] = useState("");
    const [currentTime, setCurrentTime] = useState(new Date());
    
  
    // const hours = currentTime.getHours();
    // const minutes = currentTime.getMinutes();
    // const seconds = currentTime.getSeconds();
    // const date = currentTime.getDate();
  
    // // Format the time components as a string
    // const formattedTime = `${hours}:${minutes}:${seconds}:${date}`;
  
    const enquiryCollectionRef = collection(db, "enquiryDetails");
     
  
  
  
    const onSubmitEnquiry = async (e) => {
      e.preventDefault();
      try {
        await addDoc(enquiryCollectionRef, {
          PersonName : name,
          CompanyMail : mail,
          CompanyPhone : companyPhone,
          Message : message,
          SubmittingTime : String(currentTime),
          Status : "pending"
          }); 
          e.target.reset();
        
  
      } catch (err) {
        console.error(err);
      }
    };
  
    const getEniqury = async () => {
      try {
        const data = await getDocs(enquiryCollectionRef);
        const filteredData = data.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setList(filteredData);
        console.log("filteredData",filteredData);
      } catch (err) {
        console.error(err);
      }
    };
  
    useEffect(()=>{
      getEniqury()
    },[])
  
    return (
        <>
            {/* Start */}
            <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="contact">
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h6 className="text-orange-600 text-base font-medium uppercase mb-2">Contact us</h6>
                        <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">Get In Touch !</h3>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 mt-8 items-center gap-6">
                        <div className="lg:col-span-8">
                            <div className="p-6 rounded-md shadow bg-white dark:bg-slate-900">
                            <form onSubmit={onSubmitEnquiry}>
                                    <div className="grid lg:grid-cols-12 lg:gap-6">
                                        <div className="lg:col-span-6 mb-5">
                                            <input name="name" type="text" className="form-input" placeholder="Name :" onChange={(e)=>setName(e.target.value)} />
                                        </div>

                                        <div className="lg:col-span-6 mb-5">
                                            <input name="email" type="email" className="form-input" placeholder="Email :" onChange={(e)=>setMail(e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-1">
                                        <div className="mb-5">
                                            <input name="Number"  className="form-input" placeholder="Phone Number :" onChange={(e)=>setCompanyPhone(e.target.value)} />
                                        </div>

                                        <div className="mb-5">
                                            <textarea name="comments" className="form-input textarea h-28" placeholder="Message :" onChange={(e)=>setMessage(e.target.value)}></textarea>
                                        </div>
                                    </div>
                                    <button type="submit" name="send" className="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-md h-11 justify-center flex items-center">Send Message</button>
                                </form>
                            </div>
                        </div>

                        <div className="lg:col-span-4">
                            <div className="lg:ms-8">
                                <div className="flex">
                                    <div className="icons text-center mx-auto">
                                        <i className="uil uil-phone block rounded text-2xl dark:text-white mb-0"></i>
                                    </div>

                                    <div className="flex-1 ms-6">
                                        <h5 className="text-lg dark:text-white mb-2 font-medium">Phone</h5>
                                        <a href="tel:+91 90350 31773" className="text-slate-400"> +91 90350 31773 </a><br/>
                                        <a href="tel:+91 72990 08866" className="text-slate-400"> +91 72990 08866 </a><br/>
                                        <a href="tel:+91 99440 90370" className="text-slate-400"> +91 99440 90370 </a>
                                    </div>
                                </div>

                                <div className="flex mt-4">
                                    <div className="icons text-center mx-auto">
                                        <i className="uil uil-envelope block rounded text-2xl dark:text-white mb-0"></i>
                                    </div>

                                    <div className="flex-1 ms-6">
                                        <h5 className="text-lg dark:text-white mb-2 font-medium">Email</h5>
                                        <a href="mailto:info@jkep.net" className="text-slate-400">info@jkep.net</a>
                                    </div>
                                </div>

                                <div className="flex mt-4">
                                    <div className="icons text-center mx-auto">
                                        <i className="uil uil-map-marker block rounded text-2xl dark:text-white mb-0"></i>
                                    </div>

                                    <div className="flex-1 ms-6">
                                        <h5 className="text-lg dark:text-white mb-2 font-medium">Office Address</h5>
                                        <p className="text-slate-400 mb-2">5A, Adinanth Avenue, Santhoshpuram, vengaiasal, Chennai - 600 073.</p>
                                        <h5 className="text-lg dark:text-white mb-2 font-medium">Plant Address</h5>
                                        <p className="text-slate-400 mb-2">Plot No.248, Temple View
                                        Gardens, Oragadam Panruti
                                        Automotive Industrial
                                        Corridor-Kandigai,
                                        Kancheepuram- 602 105.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );

}
